import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import PostListing from "../components/PostListing/PostListing";
import config from "../../data/SiteConfig";

export default class CategoryTemplate extends React.Component {
  render() {
    const { category } = this.props.pageContext;
    const postEdges = this.props.data.allMarkdownRemark.edges;
    return (
      <Layout>
        <div className="category-container flex column center">
          <Helmet
            title={`${category} | ${config.siteTitle}`}
          />
        <PostListing
          postEdges={postEdges}
          discView={this.props.data.discView.childImageSharp.gatsbyImageData}
          quoteView={this.props.data.quoteView.childImageSharp.gatsbyImageData}
          masonryView={this.props.data.masonryView.childImageSharp.gatsbyImageData} />
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CategoryPage($category: String) {
    allMarkdownRemark: allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            date
            category
            quote
            author
            reviewlink
            photo
            alt
            img {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 340)
              }
            }
          }
        }
      }
    }
    discView: file(relativePath: { eq: "img/SEO56.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    quoteView: file(relativePath: { eq: "img/SEO49crop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    masonryView: file(relativePath: { eq: "img/SEO43crop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
